.bx-wrapper {
    position: relative;
    margin: 0 auto 60px;
    padding: 0;
    zoom: 1
}

.bx-wrapper img {
    max-width: 100%;
    display: block
}

.bx-wrapper .bx-viewport {
    -moz-box-shadow: 0 0 5px #ccc;
    -webkit-box-shadow: 0 0 5px #ccc;
    box-shadow: 0 0 5px #ccc;
    border: 5px solid #fff;
    left: -5px;
    background: #fff;
    -webkit-transform: translatez(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    transform: translatez(0)
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    position: absolute;
    bottom: -30px;
    width: 100%
}

.bx-wrapper .bx-loading {
    min-height: 50px;
    background: url('../fileadmin/img/bx_loader.gif') center center no-repeat #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000
}

.bx-wrapper .bx-pager {
    text-align: center;
    font-size: .85em;
    font-family: Arial;
    font-weight: bold;
    color: #666;
    padding-top: 20px
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    zoom: 1;
    display: inline
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #000
}

.bx-wrapper .bx-prev {
    left: 10px;
    background: url('vhs-assets-css-ef6c2ae166b1d3b4080d62afcaa08696.png') no-repeat 0 -32px
}

.bx-wrapper .bx-next {
    right: 10px;
    background: url('vhs-assets-css-ef6c2ae166b1d3b4080d62afcaa08696.png') no-repeat -43px -32px
}

.bx-wrapper .bx-prev:hover {
    background-position: 0 0
}

.bx-wrapper .bx-next:hover {
    background-position: -43px 0
}

.bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 9999
}

.bx-wrapper .bx-controls-direction a.disabled {
    display: none
}

.bx-wrapper .bx-controls-auto {
    text-align: center
}

.bx-wrapper .bx-controls-auto .bx-start {
    display: block;
    text-indent: -9999px;
    width: 10px;
    height: 11px;
    outline: 0;
    background: url('vhs-assets-css-ef6c2ae166b1d3b4080d62afcaa08696.png') -86px -11px no-repeat;
    margin: 0 3px
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
    background-position: -86px 0
}

.bx-wrapper .bx-controls-auto .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    outline: 0;
    background: url('vhs-assets-css-ef6c2ae166b1d3b4080d62afcaa08696.png') -86px -44px no-repeat;
    margin: 0 3px
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
    background-position: -86px -33px
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
    text-align: left;
    width: 80%
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
    right: 0;
    width: 35px
}

.bx-wrapper .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(80, 80, 80, 0.75);
    width: 100%
}

.bx-wrapper .bx-caption span {
    color: #fff;
    font-family: Arial;
    display: block;
    font-size: .85em;
    padding: 10px
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../../Fonts/OpenSansLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../../Fonts/OpenSansRegular.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../../Fonts/OpenSansLightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../../Fonts/OpenSansRegularItalic.ttf) format('truetype');
}

@media screen and (max-width: 1009px) {

    DIV.csc-textpic-above DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-below DIV.csc-textpic-imagewrap,
    DIV.csc-textpic DIV.csc-textpic-imagewrap UL LI,
    DIV.csc-textpic DIV.csc-textpic-imagewrap DL.csc-textpic-image {
        float: none;
        display: inline-block;
        vertical-align: top;
    }

    DIV.csc-textpic-left DIV.csc-textpic-imagewrap .csc-textpic-image,
    DIV.csc-textpic-intext-left-nowrap DIV.csc-textpic-imagewrap .csc-textpic-image,
    DIV.csc-textpic-intext-left DIV.csc-textpic-imagewrap .csc-textpic-image {
        display: inline-block;
    }

    DIV.csc-textpic-imagewrap UL.csc-textpic-imagerow-none .csc-textpic-image {
        display: block;
    }

    .csc-default {
        clear: both;
    }

    /* optional */
    /* Golden Grid Layout für Bild im Text*/
    DIV.csc-textpic-intext-right-nowrap DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-left-nowrap DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-right DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-left DIV.csc-textpic-imagewrap {
        width: 38%;
        /* Bildcontainer Breite */
    }

    DIV.csc-textpic-intext-right-nowrap .csc-textpic-text,
    DIV.csc-textpic-intext-left-nowrap .csc-textpic-text {
        margin-left: 40%;
        /* Textblock */
    }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box
}

body {
    background: #ececec;
    color: #3d3d3d;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow-x: hidden;
    width: 100vw
}

@media screen and (min-width:1010px) {
    body {
        font-size: 20px
    }
}

section {
    display: block;
    overflow-x: hidden;
    padding: 1em 2em 4em 2em;
    position: relative
}

@media screen and (min-width:1010px) {
    section {
        padding: 0 0 4em 0
    }
}

section.content {
    overflow-x: visible;
    padding: 0
}

section.page-content {
    padding-left: 1em;
    padding-right: 1em
}

.wrap {
    width: 100%;
    display: block;
    margin: 0 auto;
    clear: both;
    position: relative
}

@media screen and (min-width:1010px) {
    .wrap {
        width: 1010px
    }
}

.wrap.wrap--start {
    padding: 1em 1em 4em
}

.row {
    width: 100%;
    display: block;
    clear: both
}

.separator {
    width: 100%;
    text-align: center;
    margin: 0 0 1em 0
}

.separator img {
    height: auto;
    width: 100%
}

@media screen and (min-width:1200px) {
    .separator img {
        width: auto
    }
}

.blue {
    color: #103059
}

.small {
    font-size: .66em
}

.firstline {
    display: block;
    margin-left: -0.5em
}

h1,
h2,
h3 {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 300
}

h1 {
    font-size: 2.33em;
    padding-top: .25em;
    margin: 1.5em 0 2em 1em
}

@media screen and (max-width:400px) {
    h1 {
        font-size: 2em
    }
}

@media screen and (min-width:1010px) {
    h1 {
        font-size: 3em;
        margin-left: 0;
        padding-top: 0
    }
}

h1.head {
    display: inline-block;
    position: relative;
    vertical-align: top;
    z-index: 9
}

h1.head:before {
    position: absolute;
    content: '';
    height: 30px;
    width: 50vw;
    background: #3d3d3d;
    right: 105%;
    top: 5px
}

h1.head:after {
    position: absolute;
    display: block;
    content: '';
    height: 30px;
    width: 75vw;
    background: #3d3d3d;
    left: 125%;
    top: 5px
}

h1.twolines {
    padding-left: .5em;
    line-height: .4em
}

h1.twolines.shadow {
    color: white;
    font-size: 5em;
    display: inline-block;
    right: -0.5em;
    vertical-align: top;
    margin: .5em 0 0 -2em;
    position: relative;
    z-index: 0
}

h1.twolines.shadow:after {
    left: 70%;
    top: 45px
}

@media screen and (max-width:1009px) {
    h1.twolines.shadow {
        display: none
    }
}

h2 {
    font-size: 2em;
    margin: 1.66em 0
}

h3 {
    font-size: 1.66em;
    margin: 1.5em 0
}

p+p {
    margin-top: 1em
}

a {
    color: #3d3d3d;
    text-decoration: none
}

a:hover {
    text-decoration: underline
}

i {
    padding-right: .25em
}

strong {
    font-weight: 700
}

em {
    font-style: italic
}

#scrolltop {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background: url('vhs-assets-css-02d58495488ca21ca81e1470c421a492.png') top left no-repeat;
    background-size: contain;
    display: none
}

#scrolltop:hover {
    cursor: pointer
}

div.csc-textpic-imagewrap {
    max-width: 100%;
    text-align: center
}

div.csc-textpic-imagewrap img {
    width: 66%;
    height: auto
}

@media screen and (min-width:1010px) {
    div.csc-textpic-imagewrap img {
        width: auto
    }
}

div.csc-textpic-border div.csc-textpic-imagewrap img {
    border: 10px solid white
}

#logo {
    display: block;
    margin: -3px auto 0 auto;
    width: 100%;
    height: auto
}

@media screen and (min-width:1010px) {
    #logo {
        width: auto
    }
}

.img--hero {
    width: 100%;
    height: auto
}

.menu--boxes {
    margin-top: 1em;
    width: 100%;
    text-align: center
}

.menu--boxes>* {
    background-size: cover !important;
    display: inline-block;
    border: 3px solid white;
    position: relative;
    width: 10em;
    height: 5.75em
}

@media screen and (max-width:565px) {
    .menu--boxes>* {
        font-size: .66em;
        width: 10em;
        background-position: 50% !important;
        background-size: cover !important
    }
}

@media screen and (max-width:435px) {
    .menu--boxes>* {
        font-size: .52em;
        width: 70px;
        height: 70px;
        background-position: 50% !important;
        background-size: cover !important
    }
}

.menu--boxes a {
    display: block;
    padding: .1em .5em .25em .5em;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    background: rgba(61, 61, 61, 0.5);
    font-size: 1.33em;
    font-style: italic;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    transition: all .2s;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    -ms-transition: all .2s
}

.menu--boxes a:hover {
    color: #c6c6c6;
    background: rgba(61, 61, 61, 0.6)
}

.menu--boxes .section-10 {
    background: url('vhs-assets-css-89ed35634e7ded2e3474ed729afd4d28.jpg') no-repeat
}

.menu--boxes .section-11 {
    background: url('vhs-assets-css-8ead13a91a7756501f6488ae38805b94.jpg') no-repeat
}

.menu--boxes .section-12,
.menu--boxes .section-13 {
    background: url('vhs-assets-css-655976abf957fb3e3a43a14f09660bf2.jpg') no-repeat
}

.nav-lang {
    position: absolute;
    right: 0;
    top: 0
}

.nav-lang span {
    display: inline-block;
    width: 30px;
    height: 90px;
    background: white;
    padding-top: 60px;
    text-align: center;
    position: relative;
    top: -55px;
    transition: top .5s;
    -webkit-transition: top .5s;
    -moz-transition: top .5s;
    -o-transition: top .5s;
    -ms-transition: top .5s
}

.nav-lang span:hover {
    top: -45px
}

.nav-lang span.active {
    display: none
}

.nav-social {
    position: absolute;
    top: 0;
    right: 35px
}

.nav-social li {
    display: inline-block;
    margin-right: .5em
}

.nav-social li a {
    display: inline-block;
    background: white;
    width: 30px;
    height: 90px;
    position: relative;
    top: -55px;
    transition: top .5s;
    -webkit-transition: top .5s;
    -moz-transition: top .5s;
    -o-transition: top .5s;
    -ms-transition: top .5s
}

.nav-social li a.link-facebook:after {
    color: #3d3d3d;
    content: '\f082';
    display: block;
    font-family: 'FontAwesome';
    font-size: 1.25em;
    font-style: normal;
    position: absolute;
    bottom: 6px;
    left: 8px
}

@media screen and (min-width:1010px) {
    .nav-social li a.link-facebook:after {
        bottom: 0;
        left: 4px
    }
}

.nav-social li a.link-youtube:after {
    color: #3d3d3d;
    content: '\f167';
    display: block;
    font-family: 'FontAwesome';
    font-size: 1.25em;
    font-style: normal;
    position: absolute;
    bottom: 6px;
    left: 8px
}

@media screen and (min-width:1010px) {
    .nav-social li a.link-youtube:after {
        bottom: 0;
        left: 4px
    }
}

.nav-social li a:hover {
    top: -45px
}

#navicon {
    bottom: 5px;
    margin-top: .25em;
    position: absolute;
    text-align: center;
    width: 100%
}

#navicon:hover {
    cursor: pointer
}

@media screen and (min-width:1010px) {
    #navicon {
        display: none
    }
}

.navbar-nav {
    margin: -5em 0 6em 0;
}

.navbar-nav .nav-social {
    display: none;
    right: -30px
}

.navbar-nav .nav-social ul li {
    padding: 0
}

.navbar-nav .nav-social ul li a {
    top: -60px
}

.navbar-nav.navbar-content {
    margin: -1em 0 0 0
}

.navbar-nav i {
    display: none
}

.navbar-nav.fixed {
    position: fixed;
    z-index: 10;
    top: 0;
    margin: 0;
    padding: .25em 0;
    background: #ececec;
    height: 40px;
    width: 100%;
    text-align: center
}

@media screen and (max-width:1009px) {
    .navbar-nav.fixed {
        font-size: 1em;
        height: 150px;
        top: -110px;
        transition: top .5s;
        -webkit-transition: top .5s;
        -moz-transition: top .5s;
        -o-transition: top .5s;
        -ms-transition: top .5s
    }

    .navbar-nav.fixed.open {
        top: 0px
    }

    .navbar-nav.fixed ul {
        text-align: left !important;
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        -ms-column-count: 2;
        column-gap: .5em;
        -moz-column-gap: .5em;
        -webkit-column-gap: .5em;
        -ms-column-gap: .5em
    }

    .navbar-nav.fixed ul li {
        display: block
    }

    .navbar-nav.fixed i {
        display: block
    }
}

.navbar-nav.fixed .nav-social {
    display: none
}

@media screen and (min-width:1010px) {
    .navbar-nav.fixed .nav-social {
        display: block
    }
}

.navbar-nav ul {
    width: 100%;
    text-align: center;
}

.navbar-nav ul li {
    display: inline-block;
    padding: 0 .66em
}

.navbar-nav ul li a {
    color: #3d3d3d;
    text-decoration: none;
    font-style: italic
}

.navbar-nav ul li a:hover {
    text-decoration: underline
}

.columns {
    width: 100%;
    margin: 0
}

.columns>div {
    display: inline-block;
    vertical-align: top
}

.columns--5050 .left,
.columns--5050 .right {
    margin: 0;
    width: 100%
}

.columns--5050 .right {
    margin-top: 1.5em
}

@media screen and (min-width:1010px) {
    .columns--5050 .left {
        width: 48%;
        margin-right: 1em
    }

    .columns--5050 .right {
        width: 48%
    }
}

.columns--6633 .left,
.columns--6633 .right {
    margin: 0;
    width: 100%
}

.columns--6633 .right {
    margin-top: 1.5em
}

@media screen and (min-width:1010px) {
    .columns--6633 .left {
        width: 66%;
        margin-right: 1em
    }

    .columns--6633 .right {
        width: 30%
    }

    .columns--6633 .right img {
        width: 100%;
        height: auto
    }
}

.bx-wrapper .bx-viewport {
    box-shadow: 0 0 0 0;
    -webkit-box-shadow: 0 0 0 0;
    -moz-box-shadow: 0 0 0 0;
    -ms-box-shadow: 0 0 0 0;
    -o-box-shadow: 0 0 0 0;
    border: none;
    background: transparent;
    left: 0
}

.bx-wrapper .bx-viewport .slider-image img {
    border: 15px solid white;
    width: 100%;
    height: auto
}

.bx-wrapper .bx-controls-direction a {
    height: 48px;
    width: 48px;
    margin-top: -24px;
    z-index: 0
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-prev {
    background: url('vhs-assets-css-834de7dbeb034cededa1d4bd3c23aa15.png') 0 0 no-repeat
}

.bx-wrapper .bx-next {
    background-position: 0px 0px;
    right: -45px
}

@media screen and (min-width:1010px) {
    .bx-wrapper .bx-next {
        right: -50px
    }
}

.bx-wrapper .bx-next:hover {
    background-position: 0 -48px
}

.bx-wrapper .bx-prev {
    background-position: -48px 0;
    left: -45px
}

@media screen and (min-width:1010px) {
    .bx-wrapper .bx-prev {
        left: -50px
    }
}

.bx-wrapper .bx-prev:hover {
    background-position: -48px -48px
}

.gallery-wrap {
    padding: 0 1em
}

@media screen and (min-width:1010px) {
    .gallery-wrap {
        padding: 0
    }
}

.gallery-wrap .bx-wrapper .bx-next {
    right: -45px
}

.gallery-wrap .bx-wrapper .bx-prev {
    left: -45px
}

.tx-zimmermann-horses {
    padding: 0 1em
}

@media screen and (min-width:1010px) {
    .tx-zimmermann-horses {
        padding: 0
    }
}

.tx-zimmermann-horses .bx-wrapper {
    max-width: 100% !important
}

.tx-zimmermann-horses .bx-wrapper .bx-controls-direction a {
    top: 125px
}

@media screen and (min-width:1010px) {
    .tx-zimmermann-horses .bx-wrapper .bx-controls-direction a {
        top: 170px
    }
}

@media screen and (min-width:1010px) {
    .tx-zimmermann-horses .bx-wrapper .bx-next {
        right: -40px
    }
}

.tx-zimmermann-horses .link-overview {
    float: right;
    margin-top: 1.33em
}

.horse-slider .infobox {
    display: inline-block;
    width: 19em !important;
    margin-right: 1em
}

@media screen and (max-width:320px) {
    .horse-slider .infobox {
        width: 16.5em !important;
        margin-left: .125em
    }
}

@media screen and (min-width:1010px) {
    .horse-slider .infobox {
        width: 16em !important
    }
}

.horse-slider .infobox .infobox--image {
    position: relative;
    border: 5px solid white;
    width: 100%;
    height: auto
}

.horse-slider .infobox .infobox--image img {
    max-width: 320px;
    height: auto !important;
    width: 100%
}

@media screen and (max-width:320px) {
    .horse-slider .infobox .infobox--image img {
        height: auto
    }
}

.horse-slider .infobox .infobox--image .overlay {
    display: block;
    width: 100%;
    padding: .25em .5em;
    color: white;
    font-size: 1.66em;
    font-style: italic;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    background: rgba(61, 61, 61, 0.5);
    transition: all .2s;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    -ms-transition: all .2s
}

.horse-slider .infobox .infobox--image:hover .overlay {
    color: #ececec;
    background: rgba(61, 61, 61, 0.6)
}

.horse-slider .infobox p {
    font-size: .8em;
    padding: .5em
}

.horse-slider .infobox i {
    padding-left: .5em
}

.horse-slider .infobox .link-detail {
    font-style: italic
}

.horses-overview .entry {
    display: inline-block;
    vertical-align: top;
    margin: 0 .25em 1em .25em;
    border: 5px solid white;
    position: relative;
    width: 9.75em;
    height: 9.75em;
    overflow: hidden
}

@media screen and (min-width:1010px) {
    .horses-overview .entry {
        width: 11em;
        height: 11em
    }
}

.horses-overview .entry img {
    width: 100%;
    height: 100%
}

.horses-overview .entry .overlay {
    color: white;
    background: rgba(61, 61, 61, 0.67);
    display: block;
    position: absolute;
    bottom: -55px;
    text-align: center;
    font-size: 0.66em;
    line-height: 1.5em;
    padding-bottom: 5px;
    transition: bottom .5s;
    -webkit-transition: bottom .5s;
    -moz-transition: bottom .5s;
    -o-transition: bottom .5s;
    -ms-transition: bottom .5s;
    width: 100%
}

@media screen and (min-width:1010px) {
    .horses-overview .entry .overlay {
        bottom: -75px
    }
}

.horses-overview .entry .overlay:first-line {
    font-size: 1.5em;
    line-height: 1.5em
}

.horses-overview .entry .overlay i {
    color: white;
    margin: .5em 0;
    padding: 0 .25em;
    vertical-align: middle
}

.horses-overview .entry:hover .overlay {
    bottom: -5px
}

.horses-details {
    overflow: visible;
    padding: 0 1em
}

.horses-details h1.head.shadow {
    font-size: 3.75em;
    margin: 1.2em 0 0 -2.66em
}

.horses-single>div {
    display: block;
    width: 100%
}

.horses-single>div.left {
    text-align: center
}

.horses-single>div.left .bx-wrapper {
    max-width: 80% !important
}

.horses-single>div.left .bx-wrapper .bx-controls-direction a {
    z-index: 0
}

.horses-single>div.left #single-images {
    max-width: 100% !important
}

.horses-single>div.left #single-images img {
    width: 100%;
    height: auto;
    border: 5px solid white
}

@media screen and (min-width:1010px) {
    .horses-single>div {
        display: inline-block;
        vertical-align: top;
        width: 48%
    }

    .horses-single>div.left {
        margin-right: 1em
    }
}

.horses-single>div.right {
    font-size: .8em;
    margin-top: 1.5em
}

@media screen and (min-width:1010px) {
    .horses-single>div.right {
        margin-top: 0
    }
}

.horses-single>div.right #youtube {
    float: right;
    margin: 0 0 .5em .5em
}

.horses-single>div.right .facts {
    clear: both;
    font-size: 1.33em
}

.horses-single>div.right dl>* {
    display: inline-block;
    vertical-align: top
}

.horses-single>div.right dl dt {
    font-weight: 700;
    min-width: 8.66em
}

.horses-single>div.right p+dl {
    margin-top: 1em
}

.horses-single>div.right dl+p {
    margin-top: 1em
}

.horses-single>div.right table {
    display: none;
    border: 1px solid #3d3d3d;
    border-collapse: collapse;
    margin-top: 30px
}

.horses-single>div.right table tr {
    border: 1px solid #3d3d3d
}

.horses-single>div.right table tr td {
    border: 1px solid #3d3d3d;
    vertical-align: middle;
    text-align: center;
    min-width: 120px
}

@media screen and (min-width:1010px) {
    .horses-single>div.right table {
        display: table
    }
}

#start {
    padding-left: 0;
    padding-right: 0
}

#section-10 {
    background: url('vhs-assets-css-015535ffcdb0792581517e289d852802.png') bottom right no-repeat;
    background-size: contain
}

#section-11 {
    background: url('vhs-assets-css-02e78a828dc22831d3394a8476bd6340.png') bottom left no-repeat;
    background-size: contain
}