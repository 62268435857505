/**
 * CSS styles for Shadowbox.
 */

/* typography */
#sb-title-inner,
#sb-info-inner,
#sb-loading-inner,
div.sb-message {
    font-family: "HelveticaNeue-Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    color: #fff;
}

/* container, overlay, & wrapper */
#sb-container {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 999;
    text-align: left;
    visibility: hidden;
    display: none;
}

#sb-overlay {
    position: relative;
    height: 100%;
    width: 100%;
}

#sb-wrapper {
    position: absolute;
    visibility: hidden;
    width: 100px;
    /* explicit width helps offset children calculate their width */
}

#sb-wrapper-inner {
    position: relative;
    border: 1px solid #303030;
    overflow: hidden;
    height: 100px;
    /* explicit height helps offset children calculate their height */
}

/* body */
#sb-body {
    position: relative;
    height: 100%;
}

#sb-body-inner {
    position: absolute;
    height: 100%;
    width: 100%;
}

#sb-player.html {
    height: 100%;
    overflow: auto;
    /* make html content scrollable */
}

#sb-body img {
    border: none;
}

/* loading */
#sb-loading {
    position: relative;
    height: 100%;
}

#sb-loading-inner {
    position: absolute;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
    width: 100%;
    text-align: center;
}

#sb-loading-inner span {
    background: url(loading.gif) no-repeat;
    padding-left: 34px;
    display: inline-block;
}

#sb-body,
#sb-loading {
    background-color: #060606;
    /* should match loading image background color */
}

/* title & info */
#sb-title,
#sb-info {
    position: relative;
    margin: 0;
    /* these must have no vertical margin or padding */
    padding: 0;
    overflow: hidden;
}

#sb-title,
#sb-title-inner {
    height: 26px;
    line-height: 26px;
}

#sb-title-inner {
    font-size: 16px;
}

#sb-info,
#sb-info-inner {
    height: 20px;
    line-height: 20px;
}

#sb-info-inner {
    font-size: 12px;
}

/* nav */
#sb-nav {
    float: right;
    height: 16px;
    padding: 2px 0;
    width: 45%;
}

#sb-nav a {
    display: block;
    float: right;
    height: 16px;
    width: 16px;
    margin-left: 3px;
    cursor: pointer;
    background-repeat: no-repeat;
}

#sb-nav-close {
    background-image: url(../fileadmin/img/close.png);
}

#sb-nav-next {
    background-image: url(../fileadmin/img/next.png);
}

#sb-nav-previous {
    background-image: url(../fileadmin/img/previous.png);
}

#sb-nav-play {
    background-image: url(../fileadmin/img/play.png);
}

#sb-nav-pause {
    background-image: url(../fileadmin/img/pause.png);
}

/* counter */
#sb-counter {
    float: left;
    width: 45%;
}

#sb-counter a {
    padding: 0 4px 0 0;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
}

#sb-counter a.sb-counter-current {
    text-decoration: underline;
}

/* messages */
div.sb-message {
    font-size: 12px;
    padding: 10px;
    text-align: center;
}

div.sb-message a:link,
div.sb-message a:visited {
    color: #fff;
    text-decoration: underline;
}