// out: ../../Public/css/style.css, compress: true
/* RESET BY MEYERWEB */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}/**/article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}

@import (inline) '../Css/fontawsome.css';
@import (inline) '../Css/shadowbox.css';
@import '_mixins/_resetcsc.less';
@import '_mixins/vhs-assets-slider-styles.less';

// MIXINS AND CONSTANTS
@blue: rgb(16,48,89);
@black: rgb(61,61,61);
@gray: rgb(236,236,236);

@gridWidth: 1010px;

.box-shadow(@x,@y,@s,@c) {
	        box-shadow: @x @y @s @c;
	-webkit-box-shadow: @x @y @s @c;
	   -moz-box-shadow: @x @y @s @c;
        -ms-box-shadow: @x @y @s @c;
         -o-box-shadow: @x @y @s @c;
}
.box-sizing(@param) {
	        box-sizing: @param;
	   -moz-box-sizing: @param;
	-webkit-box-sizing: @param;
        -ms-box-sizing: @param;
         -o-box-sizing: @param;
}
.transition(@p,@d) {
	        transition: @p @d;
	-webkit-transition: @p @d;
	   -moz-transition: @p @d;
	     -o-transition: @p @d;
        -ms-transition: @p @d;
}
.column-count(@c,@g) {
            column-count: @c;
       -moz-column-count: @c;
    -webkit-column-count: @c;
        -ms-column-count: @c;
            column-gap: @g;
       -moz-column-gap: @g;
    -webkit-column-gap: @g;
        -ms-column-gap: @g;
}
.transform(@rotate: 0deg, @scale: 1, @skew: 0deg, @translate: 0px) {
            transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
    -webkit-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
        -ms-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
}

.flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex(@values) {
  -webkit-box-flex: @values;
  -moz-box-flex:  @values;
  -webkit-flex:  @values;
  -ms-flex:  @values;
  flex:  @values;
}

.order(@val) {
  -webkit-box-ordinal-group: @val;
  -moz-box-ordinal-group: @val;
  -ms-flex-order: @val;
  -webkit-order: @val;
  order: @val;
}

/******
 * BASE ELEMENTS & UTILITIES *
 ******/
* {
    .box-sizing(border-box);
}

body {
    background: @gray;
    color: @black;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 14px;
	@media screen and (min-width: @gridWidth) {
    	font-size: 20px;
	}
    font-weight: 300;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
	overflow-x: hidden;
	width: 100vw;
	scroll-behavior: smooth;
}
section {
	display: block;
	overflow-x: hidden;
	padding: 1em 2em 4em 2em;
	position: relative;
	@media screen and (min-width: @gridWidth) {
		padding: 0 0 4em 0;
	}
	&.content {
		overflow-x: visible;
        padding: 0;
	}
    &.page-content {
        padding-left: 1em;
        padding-right: 1em;
    }
}
.wrap {
	width: 100%;
	display: block;
	margin: 0 auto;
	clear: both;
	position: relative;
	@media screen and (min-width: @gridWidth) {
		width: @gridWidth;
	}
    &.wrap--start {
        padding: 1em 1em 4em;
    }
}
.row {
	width: 100%;
	display: block;
	clear: both;
}

.separator {
	width: 100%;
	text-align: center;
	margin: 0 0 1em 0;
	img {
		height: auto;
		width: 100%;
		@media screen and (min-width: 1200px) {
			width: auto;
		}
	}
}

.blue { color: @blue; }
.small { font-size: .66em; }
.firstline {
	display: block;
	margin-left: -.5em;
}

h1,h2,h3 {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 300;
}
h1 {
	font-size: 2.33em;
	padding-top: .25em;
    @media screen and (max-width: 400px) {
        font-size: 2em;
    }
	@media screen and (min-width: @gridWidth) {
		font-size: 3em;
        margin-left: 0;
		padding-top: 0;
	}
	margin: 1.5em 0 2em 1em;
	&.head {
		display: inline-block;
		position: relative;
		vertical-align: top;
		z-index: 9;
		&:before {
			position: absolute;
			content: '';
			height: 30px;
			width: 50vw;
			background: @black;
			right: 105%;
			top: 5px;
		}
        &:after {
            position: absolute;
			display: block;
            content: '';
            height: 30px;
            width: 75vw;
            background: @black;
            left: 125%;
            top: 5px;
        }
	}
	&.twolines {
		padding-left: .5em;
		line-height: .4em;
		&.shadow {
			color: white;
			font-size: 5em;
			display: inline-block;
			right: -.5em;
			vertical-align: top;
			margin: .5em 0 0 -2em;
			position: relative;
			z-index: 0;
			&:after {
				left: 70%;
				top: 45px;
			}
			@media screen and (max-width: 1009px) {
				display: none;
			}
		}
	}
}
h2 {
	font-size: 2em;
	margin: 1.66em 0;
}
h3 {
	font-size: 1.66em;
	margin: 1.5em 0;
}

p+p {
	margin-top: 1em;
}

a {
	color: @black;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

i {
	padding-right: .25em;
}

strong {
	font-weight: 700;
}
em {
	font-style: italic;
}

#scrolltop {
	width: 30px;
	height: 30px;
	position: fixed;
	bottom: 25px;
	right: 25px;
	background: url('../fileadmin/img/scrolltop.png') top left no-repeat;
	background-size: contain;
	display: none;
	&:hover {
		cursor: pointer;
	}
}

div.csc-textpic-imagewrap {
	max-width: 100%;
	text-align: center;
	img {
		width: 66%;
		@media screen and (min-width: @gridWidth) {
			width: auto;
		}
		height: auto;
	}
}

div.csc-textpic-border div.csc-textpic-imagewrap img {
	border: 10px solid white;
}

/***
 * SITE STYLING
 ***/
#logo {
	display: block;
	margin: -3px auto 0 auto;
	width: 100%;
	height: auto;
	@media screen and (min-width: @gridWidth) {
		width: auto;
	}
}
.img--hero {
	width: 100%;
	height: auto;
}

// BOX-NAVIGATION
.menu--boxes {
	margin-top: 1em;
	width: 100%;
	text-align: center;
	& > * {
		background-size: cover !important;
		display: inline-block;
		border: 3px solid white;
		position: relative;
		width: 10em;
		height: 5.75em;
		@media screen and (max-width: 565px) {
			font-size: .66em;
			width: 10em;
			background-position: 50% !important;
			background-size: cover !important;
		}
		@media screen and (max-width: 435px) {
			font-size: .52em;
			width: 70px;
			height: 70px;
			background-position: 50% !important;
			background-size: cover !important;
		}
	}
	a {
		display: block;
		padding: .1em .5em .25em .5em;
		width: 100%;
		position: absolute;
		bottom: 0;
		color: white;
		background: fadeout(@black, 50%);
		font-size: 1.33em;
		font-style: italic;
		font-weight: 400;
		text-align: left;
		text-decoration: none;
		.transition(all,.2s);
		&:hover {
			color: darken(@gray,15%);
			background: fadeout(@black, 40%);
		}
	}
	.section-10 {
		background: url('../fileadmin/img/nav_cz.jpg') no-repeat;
	}
	.section-11 {
		background: url('../fileadmin/img/nav_springpferde.jpg') no-repeat;
	}
	.section-12,
	.section-13 {
		background: url('../fileadmin/img/nav_referenzen.jpg') no-repeat;
	}
}
// LANGUAGE NAV
.nav-lang {
	position: absolute;
	right: 0;
	top: 0;
	span {
		display: inline-block;
		width: 30px;
		height: 90px;
		background: white;
		padding-top: 60px;
		text-align: center;
		position: relative;
		top: -55px;
		.transition(top, .5s);
		&:hover {
			top: -45px;
		}
		&.active {
			display: none;
		}
	}
}
// SOCIAL NAV
.nav-social {
	position: absolute;
	top: 0;
	right: 35px;
	li {
		display: inline-block;
		margin-right: .5em;
		a {
			display: inline-block;
			background: white;
			width: 30px;
			height: 90px;
			position: relative;
			top: -55px;
			// text-indent: -99999em;
			.transition(top, .5s);
			&.link-facebook:after {
				width: 30px;
				color: @black;
				content: '\f082';
				display: block;
				font-family: 'FontAwesome';
				font-size: 1em;
				font-style: normal;
				position: absolute;
				text-align: center;
				bottom: 6px;
				left: 0;
				@media screen and (min-width: @gridWidth) {
					bottom: 0;
				}
			}
			&.link-youtube:after {
				width: 30px;
				color: @black;
				content: '\f167';
				display: block;
				font-family: 'FontAwesome';
				font-size: 1em;
				font-style: normal;
				position: absolute;
				text-align: center;
				bottom: 6px;
				left: 0;
				@media screen and (min-width: @gridWidth) {
					bottom: 0;
				}
			}
			&:hover {
				top: -45px;
			}
		}
	}
}
// NAVBAR
#navicon {
	bottom: 5px;
	margin-top: .25em;
	position: absolute;
	text-align: center;
	width: 100%;
	&:hover {
		cursor: pointer;
	}
	@media screen and (min-width: @gridWidth) {
		display: none;
	}
}
.navbar-nav {
	margin: -5em 0 6em 0;
	.nav-social {
		display: none;
		right: -30px;
		ul {
			li {
				padding: 0;
				a {
					top: -60px;
				}
			}
		}
	}
	&.navbar-content {
		margin: -1em 0 0 0;
	}
	i {
		display: none;
	}
	&.fixed {
		position: fixed;
		z-index: 10;
		top: 0;
		margin: 0;
		padding: .25em 0;
		background: @gray;
		height: 40px;
		width: 100%;
		text-align: center;
		@media screen and (max-width: 1009px) {
			font-size: 1em;
			height: 150px;
			top: -110px;
			.transition(top, .5s);
			&.open {
				top: 0px;
			}
			ul {
				text-align: left !important;
				.column-count(2, .5em);
				li {
					display: block;
				}
			}
			i {
				display: block;
			}
		}
		.nav-social {
			display: none;
			@media screen and (min-width: @gridWidth) {
				display: block;
			}
		}
	}
	ul {
		width: 100%;
		text-align: center;
		li {
			display: inline-block;
			padding: 0 .66em;
			a {
				color: @black;
				text-decoration: none;
				font-style: italic;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// MULTI COLUMN CONTENT
.columns {
	width: 100%;
	margin: 0;
	& > div {
		display: inline-block;
		vertical-align: top;
	}
}
	.columns--5050 {
		.left,
		.right {
			margin: 0;
			width: 100%;
		}
		.right { margin-top: 1.5em; }
		@media screen and (min-width: @gridWidth) {
			.left {
				width: 48%;
				margin-right: 1em;
			}
			.right {
				width: 48%;
			}
		}
	}
	.columns--6633 {
		.left,
		.right {
			margin: 0;
			width: 100%;
		}
		.right { margin-top: 1.5em; }
		@media screen and (min-width: @gridWidth) {
			.left {
				width: 66%;
				margin-right: 1em;
			}
			.right {
				width: 30%;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}

// SLIDER IMAGES
.bx-wrapper {
	.bx-viewport {
		.box-shadow(0,0,0,0);
		border: none;
		background: transparent;
		left: 0;
		.slider-image {
			img {
				border: 15px solid white;
				width: 100%;
				height: auto;
			}
		}
	}
	.bx-controls-direction a {
		height: 48px;
		width: 48px;
		margin-top: -24px;
		z-index: 0;
	}
	.bx-next,
	.bx-prev {
		background: url('../fileadmin/img/sprites_slider.png') 0 0 no-repeat;
	}
	.bx-next {
		background-position: 0px 0px;
        right: -45px;
        @media screen and (min-width: @gridWidth) {
            right: -50px;
        }
		&:hover {
			background-position: 0px -48px;
		}
	}
	.bx-prev {
		background-position: -48px 0px;
        left: -45px;
        @media screen and (min-width: @gridWidth) {
            left: -50px;
        }
		&:hover {
			background-position: -48px -48px;
		}
	}
}

// GALLERY SLIDER
.gallery-wrap {
	padding: 0 1em;
	@media screen and (min-width: @gridWidth) {
		padding: 0;
	}
	.bx-wrapper {
		.bx-next {
			right: -45px;
		}
		.bx-prev {
			left: -45px;
		}
	}
}

// HORSE SLIDER
.tx-zimmermann-horses {
	padding: 0 1em;
	@media screen and (min-width: @gridWidth) {
		padding: 0;
	}
	.bx-wrapper {
		max-width: 100% !important;
        .bx-controls-direction a {
            top: 125px;
			@media screen and (min-width: @gridWidth) {
				top: 170px;
			}
        }
		.bx-next {
			@media screen and (min-width: @gridWidth) {
				right: -40px;
			}
		}
	}
	.link-overview {
		float: right;
		margin-top: 1.33em;
	}
}
.horse-slider {
	.infobox {
		display: inline-block;
		width: 19em !important;
		margin-right: 1em;
		@media screen and (max-width: 320px) {
			width: 16.5em !important;
			margin-left: .125em;
		}
		@media screen and (min-width: @gridWidth) {
			width: 16em !important;
		}
		.infobox--image {
			position: relative;
			border: 5px solid white;
			width: 100%;
			height: auto;
			img {
				max-width: 320px;
				height: auto !important;
				width: 100%;
				@media screen and (max-width: 320px) {
					height: auto;
				}
			}
			.overlay {
				display: block;
				width: 100%;
				padding: .25em .5em;
				color: white;
				font-size: 1.66em;
				font-style: italic;
				text-transform: uppercase;
				position: absolute;
				bottom: 0;
				background: fadeout(@black, 50%);
				.transition(all, .2s);
			}
			&:hover {
				.overlay {
					color: @gray;
					background: fadeout(@black, 40%);
				}
			}
		}
		p {
			font-size: .8em;
			padding: .5em;
		}
		i {
			padding-left: .5em;
		}
		.link-detail {
			font-style: italic;
		}
	}
}

// HORSES OVERVIEW
.horses-overview {
	.entry {
		display: inline-block;
		vertical-align: top;
		margin: 0 .25em 1em .25em;
		border: 5px solid white;
		position: relative;
		width: 9.75em;
		height: 9.75em;
        @media screen and (min-width: @gridWidth) {
            width: 11em;
            height: 11em;
        }
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
		.overlay {
			color: white;
			background: fadeout(@black, 33%);
			display: block;
			position: absolute;
			bottom: -55px;
            @media screen and (min-width: @gridWidth) {
                bottom: -75px;
            }
			text-align: center;
			font-size: 0.66em;
			line-height: 1.5em;
			padding-bottom: 5px;
			.transition(bottom, .5s);
			&:first-line {
				font-size: 1.5em;
				line-height: 1.5em;
			}
			width: 100%;
			i {
				color: white;
				margin: .5em 0;
				padding: 0 .25em;
				vertical-align: middle;
			}
		}
		&:hover {
			.overlay { bottom: -5px; }
		}
	}
}

// HORSE SINGLE VIEW
.horses-details {
	overflow: visible;
    padding: 0 1em;
	h1 {
		&.head {
			&.shadow {
				font-size: 3.75em;
				margin: 1.2em 0 0 -2.66em;
			}
		}
	}
}

.horses-single {
	> div {
		display: block;
		width: 100%;
		&.left {
			text-align: center;
			.bx-wrapper {
				max-width: 80% !important;
				.bx-controls-direction a { z-index: 0; }
			}
			#single-images {
				max-width: 100% !important;
				img {
					width: 100%;
					height: auto;
					border: 5px solid white;
				}
			}
		}
		@media screen and (min-width: @gridWidth) {
			display: inline-block;
			vertical-align: top;
			width: 48%;
			&.left {
				margin-right: 1em;
			}
		}
		&.right {
			font-size: .8em;
			margin-top: 1.5em;
			@media screen and (min-width: @gridWidth) {
				margin-top: 0;
			}
			#youtube {
				float: right;
				margin: 0 0 .5em .5em;
			}
			.facts {
				clear: both;
				font-size: 1.33em;
			}
			dl {
				> * {
					display: inline-block;
					vertical-align: top;
				}
				dt  {
					font-weight: 700;
					min-width: 8.66em;
				}
			}
			p+dl {
				margin-top: 1em;
			}
			dl+p {
				margin-top: 1em;
			}
			table {
				display: none;
				border: 1px solid @black;
				border-collapse: collapse;
				margin-top: 30px;
				tr {
					border: 1px solid @black;
					td {
						border: 1px solid @black;
						vertical-align: middle;
						text-align: center;
						min-width: 120px;
					}
				}
				@media screen and (min-width: @gridWidth) {
					display: table;
				}
			}
		}
	}
}

// SECTIONS STYLING
#start {
	padding-left: 0;
	padding-right: 0;
}
#section-10 {
	background: url('../fileadmin/img/bg_right.png') bottom right no-repeat;
	background-size: contain;
}
#section-11 {
	background: url('../fileadmin/img/bg_left.png') bottom left no-repeat;
	background-size: contain;
}
